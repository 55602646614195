/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Fullmap, FullmapWrap, Subtitle, FullmapCover, ContactForm, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Contact - Villa Club Tropicana Seychelles"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1tyk5a6 --style2 --full pb--60 pt--60" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/62f0ee50e36a4be3ac94827b062cf411_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/62f0ee50e36a4be3ac94827b062cf411_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/62f0ee50e36a4be3ac94827b062cf411_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/62f0ee50e36a4be3ac94827b062cf411_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/62f0ee50e36a4be3ac94827b062cf411_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/62f0ee50e36a4be3ac94827b062cf411_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/62f0ee50e36a4be3ac94827b062cf411_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/62f0ee50e36a4be3ac94827b062cf411_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="mt--20">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"fluprbvwpk"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"<a href=\"https://maps.apple.com/?ll=-4.570321,55.454757&amp;q=Dropped%20Pin&amp;t=m\"><span style=\"color: var(--color-custom-2);\">Carana, North East Point, Mahé, Seychelles</span><span style=\"caret-color: rgb(93, 137, 233); color: rgb(93, 137, 233);\"><br>&nbsp;Map pin:&nbsp;</span> <span style=\"font-weight: bold; color: var(--color-custom-2);\">click here</span></a><span style=\"color: var(--color-custom-2);\"><br></span>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"hih43a6w7f4"}>
          
          <FullmapWrap className="pb--20 pt--20">
          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover place={"Villa Club Tropicana Mahe Seychelles"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--60 pt--60" name={"[[UNIsecntioname12]]-1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1200}}>
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Full name"},{"name":"e-mail","type":"email","required":true,"placeholder":"e-mail"},{"name":"Phone number","type":"text","placeholder":"Phone number"},{"name":"Message","type":"textarea","required":true,"placeholder":"Message"},{"name":"Send","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--2" anim={""} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":449}}>
              
              <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"color: var(--color-custom-2);\">VILLA CLUB TROPICANA CARANA<br>SEYCHELLES</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--center" content={"<span style=\"color: var(--color-custom-2--15);\">Holiday self-catering rental. Your unique opportunity to enjoy Seychelles in true `island style.`</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box lh--16" content={"<span style=\"color: var(--color-custom-2--15);\">Upper Carana, Glacis District, Mahé, Seychelles\n<br>Business Registration number: B8435640<br>Licence number: 324496<br>info@villaclubtropicana.com\n<br>+420 737466348&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}